import React, { useState, useEffect } from 'react';
import axios from 'axios';
import dayjs from 'dayjs';
import { Table, Button, Input, Popconfirm, Form, Tooltip, Row, Col, Select, Tabs, Modal, message } from 'antd';
import { Link, useLocation } from "react-router-dom";
import { DeleteOutlined, SaveOutlined, ReloadOutlined, EditOutlined } from '@ant-design/icons';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import Toast from 'layouts/Partials/Toast';
import '../Dashboard/index.css';
import { showAddIndividualPlayerDlg } from '../AddIndividualPlayerDlg/showAddIndividualPlayerDlg';
import { showAddTeamPlayerDlg } from '../AddTeamPlayerDlg/showAddTeamPlayerDlg';

const CBL = () => {
  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [title, setTitle] = useState("");
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 5 });
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [seasonList, setSeasonList] = useState([]);
  const [uiType, setUiType] = useState("Individual");
  const [individualModalUIType, setIndividualModalUIType] = useState("personal");
  const [teamModalUIType, setTeamModalUIType] = useState("school");
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [modalData, setModalData] = useState([]);
  const [selectedSeason, setSelectedSeason] = useState("");
  const location = useLocation();

  const isEditing = (record) => record.key === editingKey;


  useEffect(() => {
    setSelectedSeason("Select a season");
    fetchData();
    fetchSeason();
  }, [location.pathname, uiType]);


  const fetchData = async () => {
    const formData = new FormData();
    formData.append('route', location.pathname);
    formData.append('ui_type', uiType);

    try {
      const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/individual_player/season`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });

      setTitle(response.data.season_type);
      const fetchedData = response.data.data.map((item, index) => ({
        ...item,
        key: String(item.id),
      }));
      setDataSource(fetchedData);
      setFilteredData(fetchedData);
    } catch (err) {
      Toast('There was an error on server!', 2);
    }
  };
  const fetchSeason = async () => {
    const formData = new FormData();
    formData.append('route', location.pathname);
    formData.append('ui_type', uiType);
    const response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/type_season`, formData, {
      headers: {
        'Content-Type': 'multipart/form-data',
        'Authorization': `Bearer ${localStorage.getItem('token')}`
      }
    });
    if (response.data != null) {
      const typeArray = response.data.season_types.map(season => season.name);
      setSeasonList(response.data.season_types);
    }
  };

  const cancel = () => {
    setEditingKey('');
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const updatedData = { ...row };
      console.log('Sending updated data:', updatedData);

      const response = await axios.put(
        `${process.env.REACT_APP_API_BASE_URL}api/individual_player/${key}`,
        updatedData,
        {
          headers: {
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.data.status === 'success') {
        const newDataSource = dataSource.map(item =>
          item.key === key ? { ...item, ...updatedData } : item
        );
        setDataSource(newDataSource);
        setFilteredData(newDataSource);
        setEditingKey('');
        Toast('Saved successfully!', 1);
      } else {
        Toast('There was an error on server!', 2);
      }
    } catch (err) {
      console.error('Validation Failed:', err);
    }
  };

  const handleEdit = (record) => {
    // setEditingSeason(record); 
    setModalData(record);
    setIsModalVisible(true);
  };

  const deleteRow = async (key) => {
    const newData = dataSource.filter((item) => item.key !== key);
    setDataSource(newData);
    setFilteredData(newData);

    const total = newData.length;
    const current = pagination.current > Math.ceil(total / pagination.pageSize) ? Math.ceil(total / pagination.pageSize) : pagination.current;
    setPagination({ ...pagination, total, current });

    const response = await axios.delete(`${process.env.REACT_APP_API_BASE_URL}api/individual_player/${key}`);

    if (response.data.status == "success") {
      Toast('Deleted successfully!', 1);
    } else {
      Toast('There was an error on server!', 2);
    }
  };





  const handlePageSizeChange = (value) => {
    setPagination({ ...pagination, pageSize: value, current: 1 });
  };

  const resetForm = () => {
    setIsModalVisible(false);
    setIndividualModalUIType("personal")
    setTeamModalUIType("school")
  };

  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = dataSource.filter(item =>
      Object.values(item).some(val => String(val).toLowerCase().includes(value.toLowerCase()))
    );
    setFilteredData(filtered);
    setPagination({ ...pagination, current: 1 });
  };


  const columns = [
    // Define your columns here (same as before)
    {
      title: 'No',
      dataIndex: 'no',
      render: (_, record, index) => {
        const { current, pageSize } = pagination;
        const displayIndex = (current - 1) * pageSize + index + 1;
        return displayIndex;
      },
      width: '5%',
    },
    { title: 'Name', dataIndex: 'name', render: (text, record) => renderEditableField('name', text, record) },
    { title: 'Gender', dataIndex: 'gender', render: (text, record) => renderEditableField('gender', text, record) },
    { title: 'Birthday', dataIndex: 'birthday' },
    { title: 'Email', dataIndex: 'email', render: (text, record) => renderEditableField('email', text, record) },
    { title: 'Phone Number', dataIndex: 'phone_number', render: (text, record) => renderEditableField('phone_number', text, record) },
    { title: 'Season', dataIndex: 'season_name' },
    { title: 'Created By', render: (text, record) => { return record.user_id == "0" ? "Admin" : record.first_name + " " + record.last_name } },
    { title: 'Created At', dataIndex: 'created_at', render: (text, record) => renderEditableField('created_at', text, record) },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Button onClick={() => save(record.key)} type="link" className="btn-admin-edit"><SaveOutlined /></Button>
            <Button onClick={cancel} type="link" className="btn-admin-edit"><ReloadOutlined /></Button>
          </>
        ) : (
          <>
            <Button onClick={() => handleEdit(record)} className="btn-edit-season">
              <EditOutlined />
            </Button>
            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRow(record.key)}>
              <Button type="link" className="btn-admin-delete"><DeleteOutlined /></Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const team_columns = [
    {
      title: 'No',
      dataIndex: 'no',
      render: (_, record, index) => {
        const { current, pageSize } = pagination;
        const displayIndex = (current - 1) * pageSize + index + 1;
        return displayIndex;
      },
    },
    { title: 'School Name', dataIndex: 'school_name', render: (text, record) => renderEditableField('school_name', text, record) },
    { title: 'Contact Number', dataIndex: 'contact_number', render: (text, record) => renderEditableField('contact_number', text, record) },
    { title: 'Email', dataIndex: 'email', render: (text, record) => renderEditableField('email', text, record) },
    { title: 'Team Name', dataIndex: 'team_name', render: (text, record) => renderEditableField('team_name', text, record) },
    { title: 'Player Number', dataIndex: 'player_number', render: (text, record) => renderEditableField('player_number', text, record) },
    { title: 'Coach Name', dataIndex: 'coach_name', render: (text, record) => renderEditableField('coach_name', text, record) },
    { title: 'Coach Email', dataIndex: 'coach_email', render: (text, record) => renderEditableField('coach_email', text, record) },
    { title: 'Created By', render: (text, record) => { return record.user_id == "0" ? "Admin" : record.first_name + " " + record.last_name } },
    { title: 'Created At', dataIndex: 'created_at', render: (text, record) => renderEditableField('created_at', text, record) },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Button onClick={() => save(record.key)} type="link" className="btn-admin-edit"><SaveOutlined /></Button>
            <Button onClick={cancel} type="link" className="btn-admin-edit"><ReloadOutlined /></Button>
          </>
        ) : (
          <>
            {/* <Button onClick={() => edit(record)} type="link" className="btn-admin-edit"><EditOutlined /></Button> */}
            <Button onClick={() => handleEdit(record)} className="btn-edit-season">
              <EditOutlined />
            </Button>
            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRow(record.key)}>
              <Button type="link" className="btn-admin-delete"><DeleteOutlined /></Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const renderEditableField = (field, text, record) => {
    const editable = isEditing(record);

    // Define required fields
    const requiredFields = ['name', 'birthday', 'email', 'phone_number']; // Add fields that must be validated

    // Define fields that require date formatting
    const dateFields = ['signature_date', 'birthday', 'created_at', 'updated_at']; // Add date fields here

    // Handle editable state
    if (editable) {
      return (
        <Form.Item
          name={field}
          style={{ margin: 0 }}
          rules={
            requiredFields.includes(field)
              ? [{ required: true, message: `${field} is required.` }]
              : []
          }
        >
          {dateFields.includes(field) ? (
            <Input type="date" />
          ) : (
            <Input />
          )}
        </Form.Item>
      );
    }

    // Handle non-editable state
    if (dateFields.includes(field) && text) {
      return <span>{dayjs(text).format('DD-MM-YYYY')}</span>;
    }

    return <span>{text}</span>;
  };

  const items = [
    {
      key: 'Individual',
      label: 'Individual',
      children: '',
    },
    {
      key: 'Team',
      label: 'Team',
      children: '',
    },
  ];

  const onChange = (key) => {
    setSearchText("");
    setUiType(key);
  };

  const individualModalUIitems = [
    {
      key: 'personal',
      label: 'Personal Information',
      children: '',
    },
    {
      key: 'emergency',
      label: 'Emergency Contact Information',
      children: '',
    },
    {
      key: 'experience',
      label: 'Basketball Experience',
      children: '',
    },
    {
      key: 'media',
      label: 'Media Information',
      children: '',
    },
    {
      key: 'consent',
      label: 'Consent & Agreement',
      children: '',
    },
    {
      key: 'league',
      label: 'League Training',
      children: '',
    },
  ];

  const onChangeModalType = (key) => {
    setIndividualModalUIType(key);
  };


  const teamModalUIitems = [
    {
      key: 'school',
      label: 'School Information',
      children: '',
    },
    {
      key: 'team',
      label: 'Team Information',
      children: '',
    },
    {
      key: 'player_detail',
      label: 'Player Details',
      children: '',
    },
    {
      key: 'consent',
      label: 'Consent & Agreement',
      children: '',
    },
    {
      key: 'payment',
      label: 'Payment Information',
      children: '',
    },
  ];

  const onChangeTeamModalType = (key) => {
    setTeamModalUIType(key);
  };

  const handleAdd = async () => {
    let tmpList = [...seasonList]
    tmpList = tmpList.filter((x) => x.ui_type == uiType)
    if (tmpList.length == 0) {
      message.error("There is no season for " + uiType);
      return;
    }
    let dlgRes = uiType == "Individual" ? await showAddIndividualPlayerDlg({
      seasons: tmpList,
      title: "Individual Registration"
    }) : await showAddTeamPlayerDlg({
      seasons: tmpList,
      title: "Team Registration"
    })
    if (dlgRes == null) return
    fetchData();
  }
  return (
    <AdminLayout>
      <Row className="table-nav">
        <Col className='d-flex admin-season-selection'>
          <Select
            showSearch
            style={{ marginBottom: '11px', width: '250px' }}
            placeholder="Select a season"
            value={selectedSeason || undefined}
            filterOption={(input, option) =>
              (option?.label ?? '').toLowerCase().includes(input.toLowerCase())
            }
            options={seasonList.map((season) => ({
              value: season.name,
              label: season.name,
            }))}
            onChange={async (value) => {
              setSelectedSeason(value);  // Set selected season
              const formData = new FormData();
              formData.append('type', title); // ensure 'title' is defined in your scope
              formData.append('season', value);
              formData.append("ui_type", uiType);
              try {
                // Make an API request
                const response = await axios.post(
                  `${process.env.REACT_APP_API_BASE_URL}api/individual_player/season-select`,
                  formData,
                  {
                    headers: {
                      'Content-Type': 'multipart/form-data',
                      'Authorization': `Bearer ${localStorage.getItem('token')}`,
                    },
                  }
                );

                let response_result = [];
                if (response.data.status === 'success') {
                  response_result = response.data.data;
                } else {
                  response_result = [];
                }

                // Map the response data to include unique keys
                const fetchedData = response_result.map((item) => ({
                  ...item,
                  key: String(item.id),
                }));

                // Update the table data source
                setDataSource(fetchedData);
                setFilteredData(fetchedData);
              } catch (error) {
                console.error('Error fetching data:', error);
              }
            }}
          />
        </Col>
        <Col>
          <Row justify={'center'} align={'middle'} gutter={[16, 16]}>
            <Col><Input
              placeholder="Search..."
              value={searchText}
              allowClear={true}
              onChange={(e) => handleSearch(e.target.value)}
            /></Col>
            <Col><Button type="primary" onClick={handleAdd} style={{ height: '38px' }}>
              Add
            </Button></Col>
          </Row>
        </Col>
      </Row>

      <div>
        <Tabs defaultActiveKey={uiType} items={items} onChange={onChange} />
      </div>

      <div style={{ overflowX: 'auto', border: '1px solid #f0f0f0', borderRadius: '4px', overflowY: 'hidden' }}>
        <Form form={form} component={false}>
          {uiType == "Individual" && (
            <Table
              rowKey="key"
              pagination={{
                ...pagination,
                total: filteredData.length,
                onChange: (page, pageSize) => setPagination({ current: page, pageSize }),
                showSizeChanger: true,
                onShowSizeChange: handlePageSizeChange,
              }}
              dataSource={filteredData.slice((pagination.current - 1) * pagination.pageSize, pagination.current * pagination.pageSize)}
              columns={columns}
            />
          )}

          {uiType == "Team" && (
            <Table
              rowKey="key"
              pagination={{
                ...pagination,
                total: filteredData.length,
                onChange: (page, pageSize) => setPagination({ current: page, pageSize }),
                showSizeChanger: true,
                onShowSizeChange: handlePageSizeChange,
              }}
              dataSource={filteredData.slice((pagination.current - 1) * pagination.pageSize, pagination.current * pagination.pageSize)}
              columns={team_columns}
            />
          )}
        </Form>
      </div>
      {
        uiType == "Individual" && (
          <Modal
            // key={editingSeason?.key}
            key={modalData.key}
            title={"Detail"}
            visible={isModalVisible}
            width="1100px"
            onCancel={resetForm}
            footer={[
              // Cancel Button
              <Button key="cancel" onClick={resetForm} type="default">
                Cancel
              </Button>,

              // OK (Save) Button
              <Button
                key="submit"
                type="primary"
                onClick={resetForm}
              >
                OK
              </Button>,
            ]}
          >
            <div>
              <Tabs defaultActiveKey={individualModalUIType} items={individualModalUIitems} onChange={onChangeModalType} />
            </div>
            <div>
              {individualModalUIType == "personal" && <div>
                <Form
                  initialValues={{
                    name: modalData.name || '',
                    gender: modalData.gender || '',
                    birthday: modalData.birthday || '',
                    email: modalData.email || '',
                    phone_number: modalData.phone_number || '',
                    city: modalData.city || '',
                    address: modalData.address || '',
                    postal_code: modalData.postal_code || '',
                  }}
                  className="admin-season"
                >
                  <Row gutter={4}>
                    <Col sm={12}>
                      <div>Name</div>
                      <Form.Item
                        name="name"
                      >
                        <Input placeholder="Enter Name" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Gender</div>
                      <Form.Item
                        name="gender"
                      >
                        <Input placeholder="Enter Gender" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Birthday</div>
                      <Form.Item
                        name="birthday"
                      >
                        <Input placeholder="Enter Birthday" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Email</div>
                      <Form.Item
                        name="email"
                      >
                        <Input placeholder="Enter Email" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Phone Number</div>
                      <Form.Item
                        name="phone_number"
                      >
                        <Input placeholder="Enter Phone Number" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>City</div>
                      <Form.Item
                        name="city"
                      >
                        <Input placeholder="Enter City" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Address</div>
                      <Form.Item
                        name="address"
                      >
                        <Input placeholder="Enter Address" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Postal Code</div>
                      <Form.Item
                        name="postal_code"
                      >
                        <Input placeholder="Enter Postal Code" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>}
              {individualModalUIType == "emergency" && <div>
                <Form
                  initialValues={{
                    emergency_contact_name: modalData.emegency_contact_name || '',
                    relationship: modalData.relationship || '',
                    emegency_phone_number: modalData.emegency_phone_number || '',
                  }}
                  className="admin-season"
                >
                  <Row gutter={4}>
                    <Col sm={12}>
                      <div>Emergency Contact Name</div>
                      <Form.Item
                        name="emergency_contact_name"
                      >
                        <Input placeholder="Enter Emergency Contact Name" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Relationship</div>
                      <Form.Item
                        name="relationship"
                      >
                        <Input placeholder="Enter Relationship" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Emergency Phone Number</div>
                      <Form.Item
                        name="emegency_phone_number"
                      >
                        <Input placeholder="Enter Emegency Phone Number" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>}
              {individualModalUIType == "experience" && <div>
                <Form
                  initialValues={{
                    competition_level: modalData.competition_level || '',
                    clubs: modalData.clubs || '',
                    years_experience: modalData.years_experience || '',
                    preffered_position: modalData.preffered_position || '',
                    height: modalData.height || '',
                    dominant: modalData.dominant || '',
                  }}
                  className="admin-season"
                >
                  <Row gutter={4}>
                    <Col sm={12}>
                      <div>Competition Level</div>
                      <Form.Item
                        name="competition_level"
                      >
                        <Input placeholder="Enter Competition Level" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Clubs</div>
                      <Form.Item
                        name="clubs"
                      >
                        <Input placeholder="Enter Clubs" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Years Experience</div>
                      <Form.Item
                        name="years_experience"
                      >
                        <Input placeholder="Enter Years Experience" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Preferred Position</div>
                      <Form.Item
                        name="preffered_position"
                      >
                        <Input placeholder="Enter Preferred Position" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Height</div>
                      <Form.Item
                        name="height"
                      >
                        <Input placeholder="Enter Height" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Dominant</div>
                      <Form.Item
                        name="dominant"
                      >
                        <Input placeholder="Enter Dominant" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>}
              {individualModalUIType == "media" && <div>
                <Form
                  initialValues={{
                    medical_conditions: modalData.medical_conditions || '',
                    medications: modalData.medications || '',
                  }}
                  className="admin-season"
                >
                  <Row gutter={4}>

                    <Col sm={12}>
                      <div>Medical Conditions</div>
                      <Form.Item
                        name="medical_conditions"
                      >
                        <Input placeholder="Enter Medical Conditions" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Medications</div>
                      <Form.Item
                        name="medications"
                      >
                        <Input placeholder="Enter Medications" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>}
              {individualModalUIType == "consent" && <div>
                <Form
                  initialValues={{
                    signature_img_path: modalData.signature_img_path || '',
                    signature_date: dayjs(modalData.signature_date).format('DD-MM-YYYY') || '',
                    wavier_release: modalData.wavier_release || '',
                    consenting_person: modalData.consenting_person || '',
                    relationship_child: modalData.relationship_child || '',
                  }}
                  className="admin-season"
                >
                  <Row gutter={4}>
                    <Col sm={12}>
                      <div>Signature (Image Path)</div>
                      <Form.Item
                        name="signature_img_path"
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Link>
                            <img
                              src={`${process.env.REACT_APP_API_BASE_URL}uploads/media/${modalData.signature_img_path}`}
                              alt="Signature"
                              style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '8px' }}
                            />
                          </Link>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Signature (Date)</div>
                      <Form.Item
                        name="signature_date"
                      >
                        <Input placeholder="Enter Signature (Date)" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Name of Consenting Person</div>
                      <Form.Item
                        name="consenting_person"
                      >
                        <Input placeholder="Enter Name of Consenting Person" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Relationship to Child</div>
                      <Form.Item
                        name="relationship_child"
                      >
                        <Input placeholder="Enter Relationship to Child" disabled />
                      </Form.Item>
                    </Col>

                  </Row>
                </Form>
              </div>}
              {individualModalUIType == "league" && <div>
                <Form
                  initialValues={{
                    available_training: modalData.available_training || '',
                    season: modalData.season_name || '',
                    user_id: modalData.first_name ? modalData.first_name + " " + modalData.last_name || '' : "Admin",
                    created_at: dayjs(modalData.created_at).format('DD-MM-YYYY HH:mm:ss') || '',
                    updated_at: dayjs(modalData.updated_at).format('DD-MM-YYYY HH:mm:ss') || ''
                  }}
                  className="admin-season"
                >
                  <Row gutter={4}>
                    <Col sm={12}>
                      <div>Available Training</div>
                      <Form.Item
                        name="available_training"

                      >
                        <Input placeholder="Enter Available Training" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>


                      <div>Season Name</div>
                      <Form.Item
                        name="season"
                      >
                        <Input placeholder="Enter Season" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>User Name</div>
                      <Form.Item
                        name="user_id"
                      >
                        <Input placeholder="Enter User Name" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Created At</div>
                      <Form.Item
                        name="created_at"
                      >
                        <Input placeholder="Enter Created At" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Updated At</div>
                      <Form.Item
                        name="updated_at"
                      >
                        <Input placeholder="Enter Updated At" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>}
            </div>
          </Modal>
        )}

      {
        uiType == "Team" && (
          <Modal
            // key={editingSeason?.key}
            key={modalData.key}
            title={"Detail"}
            visible={isModalVisible}
            width="1100px"
            onCancel={resetForm}
            footer={[
              // Cancel Button
              <Button key="cancel" onClick={resetForm} type="default">
                Cancel
              </Button>,

              <Button
                key="submit"
                type="primary"
                onClick={resetForm}
              >
                OK
              </Button>,
            ]}
          >
            <div>
              <Tabs defaultActiveKey={teamModalUIType} items={teamModalUIitems} onChange={onChangeTeamModalType} />
            </div>
            <div>
              {teamModalUIType == "school" && <div>
                <Form
                  initialValues={{
                    school_name: modalData.school_name || '',
                    address: modalData.address || '',
                    city: modalData.city || '',
                    contact_number: modalData.contact_number || '',
                    postal_code: modalData.postal_code || '',
                    email: modalData.email || '',
                  }}
                  className="admin-season"
                >
                  <Row gutter={4}>
                    <Col sm={12}>
                      <div>School Name</div>
                      <Form.Item
                        name="school_name"
                      >
                        <Input placeholder="Enter School Name" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Address</div>
                      <Form.Item
                        name="address"
                      >
                        <Input placeholder="Enter Address" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>City</div>
                      <Form.Item
                        name="city"
                      >
                        <Input placeholder="Enter City" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Contact Number</div>
                      <Form.Item
                        name="contact_number"
                      >
                        <Input placeholder="Enter Contact Number" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Postal Code</div>
                      <Form.Item
                        name="postal_code"
                      >
                        <Input placeholder="Enter Postal Code" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Email</div>
                      <Form.Item
                        name="email"
                      >
                        <Input placeholder="Enter Email" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>}

              {teamModalUIType == "team" && <div>
                <Form
                  initialValues={{
                    team_name: modalData.team_name || '',
                    player_number: modalData.player_number || '',
                    coach_contact_number: modalData.coach_contact_number || '',
                    age_group: modalData.age_group || '',
                    coach_name: modalData.coach_name || '',
                    coach_email: modalData.coach_email || '',
                  }}
                  className="admin-season"
                >
                  <Row gutter={4}>
                    <Col sm={12}>
                      <div>Team Name</div>
                      <Form.Item
                        name="team_name"
                      >
                        <Input placeholder="Enter Team Name" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Player Number</div>
                      <Form.Item
                        name="player_number"
                      >
                        <Input placeholder="Enter Player Number" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Coach Contact Number</div>
                      <Form.Item
                        name="coach_contact_number"
                      >
                        <Input placeholder="Enter Coach Contact Number" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Age Group</div>
                      <Form.Item
                        name="age_group"
                      >
                        <Input placeholder="Enter Age Group" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Coach Name</div>
                      <Form.Item
                        name="coach_name"
                      >
                        <Input placeholder="Enter Coach Name" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Coach Email</div>
                      <Form.Item
                        name="coach_email"
                      >
                        <Input placeholder="Enter Coach Email" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>}

              {teamModalUIType == "player_detail" && <div>
                <Form
                  initialValues={{
                    player_file_path: modalData.player_file_path || '',
                  }}
                  className="admin-season"
                >
                  <Row gutter={4}>
                    <Col sm={12}>
                      <div>Player Info</div>
                      {modalData.player_file_path ?
                        <Form.Item
                          label="Uploaded Player Data"
                          name="player_file_path"
                          className="mt-20"
                        >
                          <a
                            download={modalData.player_file_path}
                            href="data:application/octet-stream;base64,PD94ANDSOON"
                          >
                            {modalData.player_file_name}
                          </a>

                        </Form.Item> : "None"}
                    </Col>

                  </Row>
                </Form>
              </div>}

              {teamModalUIType == "consent" && <div>
                <Form
                  initialValues={{
                    approving_school_name: modalData.approving_school_name || '',
                    position: modalData.position || '',
                    signature_path: modalData.signature_path || '',
                    signature_date: dayjs(modalData.signature_date).format('DD-MM-YYYY') || '',
                  }}
                  className="admin-season"
                >
                  <Row gutter={4}>
                    <Col sm={12}>
                      <div>Approving School Name</div>
                      <Form.Item
                        name="approving_school_name"
                      >
                        <Input placeholder="Enter Approving School Name" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Position</div>
                      <Form.Item
                        name="position"
                      >
                        <Input placeholder="Enter Position" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Signature Path</div>
                      <Form.Item
                        name="signature_path"
                      >
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                          <Link>
                            <img
                              src={`${process.env.REACT_APP_API_BASE_URL}uploads/media/${modalData.signature_path}`}
                              alt="Signature"
                              style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '8px' }}
                            />
                          </Link>
                        </div>
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Signature Date</div>
                      <Form.Item
                        name="signature_date"
                      >
                        <Input placeholder="Enter Signature Date" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>}

              {teamModalUIType == "payment" && <div>
                <Form
                  initialValues={{
                    registration_fee: modalData.registration_fee || '',
                    season: modalData.season_name || '',
                    user_id: modalData.first_name ? modalData.first_name + " " + modalData.last_name || '' : "Admin",
                    created_at: dayjs(modalData.created_at).format('DD-MM-YYYY HH:mm:ss') || '',
                    updated_at: dayjs(modalData.updated_at).format('DD-MM-YYYY HH:mm:ss') || ''
                  }}
                  className="admin-season"
                >
                  <Row gutter={4}>
                    <Col sm={12}>
                      <div>Registration Fee</div>
                      <Form.Item
                        name="registration_fee"
                      >
                        <Input placeholder="Enter Registration Fee" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Season Name</div>
                      <Form.Item
                        name="season"
                      >
                        <Input placeholder="Enter Season" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>User Name</div>
                      <Form.Item
                        name="user_id"
                      >
                        <Input placeholder="Enter User Name" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Created At</div>
                      <Form.Item
                        name="created_at"
                      >
                        <Input placeholder="Enter Season Name" disabled />
                      </Form.Item>
                    </Col>
                    <Col sm={12}>
                      <div>Updated At</div>
                      <Form.Item
                        name="updated_at"
                      >
                        <Input placeholder="Enter Season Name" disabled />
                      </Form.Item>
                    </Col>
                  </Row>
                </Form>
              </div>}

            </div>

          </Modal>
        )}
    </AdminLayout>
  );
};

export default CBL;