import React, { useState, useEffect } from 'react';
import AnimatedSection from 'components/AnimatedSection/AnimatedSection';
import Toast from 'layouts/Partials/Toast';
import axios from 'axios';
import './index.css';
import { Form, Input, Button, Checkbox, Row, Col } from 'antd';
import ImgAuth from 'assets/png/admin_player.png';
import { ROUTE_ADMIN_DASHBOARD } from 'constants/navigation_constants';
const Login = ({ history }) => {
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            window.location.href = ROUTE_ADMIN_DASHBOARD;
        }
    }, [history]);

    const [loginStatus, setLoginStatus] = useState('');

    const onFinish = async (formValues) => {
        try {
            const response = await axios.post(
                // ${process.env.REACT_APP_API_BASE_URL}api/user/api_auth
                `${process.env.REACT_APP_API_BASE_URL}api/user/api_auth`, // Change to your actual login API endpoint
                {
                    email: formValues.email,
                    password: formValues.password,
                },
                {
                    headers: {
                        'Content-Type': 'application/json',
                    },
                }
            );

            console.log(response.data);
            if (response.data.status === "success") {
                localStorage.setItem('admin_name', response.data.name);
                localStorage.setItem('admin_email', response.data.email);
                localStorage.setItem('token', JSON.stringify(response.data.token));
                window.location.href = ROUTE_ADMIN_DASHBOARD;
            } else {
                setLoginStatus(response.data.message);
                Toast('Incorrect email and password. Please try again.', 2);
            }
        } catch (error) {
            console.error('Login failed:', error);
            if (axios.isAxiosError(error)) {
                const errorMessage = error.response?.data?.message || 'An error occurred';
                setLoginStatus(errorMessage);
            }
        }
    };

    return (
        <Row gutter={16} className="admin-signin-content">
            <Col sm={15} >
                <div className="bg-signin">
                    <img className="" width="380" src={ImgAuth} alt="" />
                    <div className="responsive-branch" >
                        <div className="bottom-title  mt-30">
                            <div className='font-48 typo-blue'>
                                <AnimatedSection type="fade">Coastal Basketball <div className='font-48 typo-red'>Admin Panel</div></AnimatedSection>
                            </div>
                        </div>
                    </div>
                </div>
            </Col>
            <Col sm={9} className="auth-part">
                <h1 className="title">Welcome to our Coastal Basketball</h1>
                <Form
                    name="normal_login"
                    className="login-form"
                    initialValues={{
                        remember: true,
                    }}
                    onFinish={onFinish}
                >
                    <Form.Item
                        name="email"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Email!',
                            },
                        ]}
                    >
                        <Input placeholder="Email" />
                    </Form.Item>
                    <Form.Item
                        style={{ marginTop: '40px' }}
                        name="password"
                        rules={[
                            {
                                required: true,
                                message: 'Please input your Password!',
                            },
                        ]}
                    >
                        <Input
                            type="password"
                            placeholder="Password"
                        />
                    </Form.Item>

                    <Form.Item >
                        <Form.Item name="remember" valuePropName="checked" noStyle>
                            <Checkbox>Remember me</Checkbox>
                        </Form.Item>
                    </Form.Item>

                    <Form.Item>
                        <Button type="primary" htmlType="submit" className="login-form-button">
                            Log in
                        </Button>
                    </Form.Item>
                </Form>
            </Col>
        </Row>
    );
};

export default Login;