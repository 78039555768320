import React, { useState, useEffect } from "react";
import { Modal, Row, Col, Button, ConfigProvider, Space, Form, Input, DatePicker, message, notification } from "antd";
import { createStyles } from 'antd-style';
import Logo from "assets/png/logo.png";
import { Link } from "react-router-dom";
import { LinkOutlined, LoginOutlined, ToTopOutlined, EyeOutlined, EyeInvisibleOutlined } from "@ant-design/icons";
import axios from 'axios';
import Toast from 'layouts/Partials/Toast';

import './index.css';

const useStyle = createStyles(({ prefixCls, css }) => ({
  linearGradientButton: css`
    &.${prefixCls}-btn-primary:not([disabled]):not(.${prefixCls}-btn-dangerous) {
      border-width: 0;

      > span {
        position: relative;
      }

      &::before {
        content: '';
        background: linear-gradient(135deg, #6253e1, #04befe);
        position: absolute;
        inset: 0;
        opacity: 1;
        transition: all 0.3s;
        border-radius: inherit;
      }

      &:hover::before {
        opacity: 0;
      }
    }
  `,
  modalContent: css`
    padding: 20px; 
  `
}));

const ModalRegistration = ({ modal2Open, setModal2Open }) => {
  const [isCblJcbl, setCblJcbl] = useState(false);
  const [isHighSchool, setHighSchool] = useState(false);
  const [modalSignin, setModalSignin] = useState(false);
  const [modalSignup, setModalSignup] = useState(false);
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [confirmPasswordVisible, setConfirmPasswordVisible] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [signinResponse, setSigninResponse] = useState("");
  const [signupResponse, setSignupResponse] = useState("");
  const [openSeason, setOpenSeason] = useState([]);
  const [seasonData, setSeasonData] = useState([]);
  const [isAuth, setIsAuth] = useState(false);
  const { styles } = useStyle();

  const openDangerNotification = (props) => {
    notification.config({
      placement: 'topRight', // Set the position to bottom left
      duration: 2,
    });

    let msg = '';
    let notificationType = '';

    if (props === 1) {
      msg = "Registration successful!";
      notificationType = 'success'; // Set to success for successful registration
    } else if (props === 2) {
      msg = "This season registration date is passed.";
      notificationType = 'error'; // Set to error for failed registration
    } else {
      msg = "An error occurred during registration.";
      notificationType = 'error'; // General error message
    }

    if (notificationType === 'success') {
      notification.success({
        message: 'Success Notification',
        description: msg,
      });
    } else {
      notification.error({
        message: 'Error Notification',
        description: msg,
      });
    }
  };

  useEffect(() => {

    if (localStorage.getItem("client_token")) setIsAuth(true);


    fetchData();
    fetchAuthStatus();
  }, []);

  const fetchData = async () => {
    try {
      const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/open_season`, {
        headers: {
          'Authorization': `Bearer ${localStorage.getItem('token')}`,
        },
      });
      console.log("ggg:", response.data);
      setSeasonData(response.data);

      if (response.data.some(item => item.type == "CBL" || item.type == "JCBL" || item.type == "Academy")) {
        const filteredItems = response.data.filter(item => item.type == "CBL" || item.type == "JCBL" || item.type == "Academy");
        filteredItems
        .forEach(item => {
          setOpenSeason(prevState => [...prevState, "Register Individual for " + item.name]);
        });

        setCblJcbl(true);
      }

      if (response.data.some(item => item.type == "High School")) {
        const filteredItems = response.data.filter(item => item.type == "High School");
        filteredItems.forEach(item => {
          setOpenSeason(prevState => [...prevState, "Register Team for " + item.name]);
        });
        setHighSchool(true);
      }


    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const fetchAuthStatus = async () => {
    const response = await axios.post(
      `${process.env.REACT_APP_API_BASE_URL}api/auth`,
      {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('client_token')}`,
        },
      }
    );
    if (response.data.status != "success") {
      localStorage.removeItem("client_token");
    }
  }

  const handleButtonClick = (season) => {
    const currentDate = new Date();
    const formattedDate = currentDate.toISOString().split('T')[0]; // This will return the date in 'DD-MM-YYYY' format

    if (formattedDate < season.registration_end_date) {
      if (!isAuth) return;
      localStorage.setItem("season_id", season.id);
      localStorage.setItem("season_name", season.name);
      localStorage.setItem("season_type", season.type);
      localStorage.setItem("start_date", season.start_date);
      localStorage.setItem("end_date", season.end_date);
      localStorage.setItem("season_detail", season.detail);
      localStorage.setItem("regist_start_date", season.registration_start_date);
      localStorage.setItem("regist_end_date", season.registration_end_date);
      localStorage.setItem("league_traing", season.league_traing);
      if (season.ui_type?.includes("Individual")) {
        window.location.href = "/register_individual"
      } else {
        window.location.href = "/register_team"
      }
    } else {
      openDangerNotification(2);
    }


  };

  return (
    <div className="auth-content">
      <Modal
        wrapClassName="vertical-center-modal"
        open={modal2Open}
        onOk={() => setModal2Open(false)}  // You can remove or keep these handlers depending on whether you need to manage the state
        onCancel={() => setModal2Open(false)}
        footer={null}
        style={{ marginTop: '100px' }}
      >
        <div style={{ textAlign: 'center' }}>
          <Link to="/">
            <img src={Logo} alt="Modal Image" style={{ maxWidth: '100%', marginBottom: '20px' }} />
          </Link>
        </div>
        <Row className="btn-cbl-content">

          <Col
            style={{
              width: '270px',
              marginLeft: '-45px'
            }}
          >
            {
              seasonData.map((season, index) => (
                <Button
                  key={season.id}
                  type="primary"
                  icon={<LinkOutlined />}
                  className={`btn-cbl-register ${!isAuth ? "disabled-button" : ""}`}
                  disabled={!isAuth}
                  onClick={() => handleButtonClick(season)}
                >
                  {["Individual"].includes(season.ui_type)
                    ? `Register Individual for ${season.type} ${season.name}`
                    : `Register Team for ${season.type} ${season.name}`}
                </Button>
              ))
            }


            {/* {isHighSchool && ( */}
            {/* <Link to={isAuth ? "/register_team" : "#"}>
                <Button
                  type="primary"
                  icon={<LinkOutlined />}
                  className={`btn-cbl-register ${!isAuth ? 'disabled-button' : ''}`}
                  disabled={!isAuth}
                >
                  Register Team for CBL High School
                </Button>
              </Link> */}
            {/* )} */}
            <ConfigProvider
              button={{
                className: styles.linearGradientButton,
              }}
            >
              {!isAuth && (
                <p style={{ color: 'red' }}>Please sign in to register for the season.</p>
              )}
              <Space
                style={{
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'space-between',
                  marginBottom: '20px',
                  marginTop: '10px'
                }}
              >
                {/* <Button 
                  disabled={isAuth} 
                  type="primary" 
                  size="middle" 
                  icon={<LoginOutlined />} 
                  style={{ padding: '0 33px' }} 
                  onClick={() => handleSignIn()}
                >
                  SignIn
                </Button>
                <Button 
                  disabled={isAuth} 
                  type="primary" 
                  size="middle" 
                  icon={<ToTopOutlined />} 
                  style={{ padding: '0 33px' }} 
                  onClick={() => handleSignUp()}
                >
                  SignUp
                </Button> */}
              </Space>
            </ConfigProvider>
          </Col>
        </Row>
        {!isCblJcbl && !isHighSchool && (
          <div style={{ textAlign: 'center', color: '#f00', fontSize: '19px' }}>
            There is no season. Wait a moment, please.
          </div>
        )}
      </Modal>
    </div>
  );
};

export default ModalRegistration;
