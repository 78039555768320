import React, { useEffect } from "react";
import { withRouter } from 'react-router-dom';
import DashboardLayout from 'layouts/AdminLayout/AdminLayout';
import { ROUTE_ADMIN_DASHBOARD, ROUTE_ADMIN_LOGIN } from "constants/navigation_constants";

function Dashboard({ history }) {
    useEffect(() => {
        const token = localStorage.getItem('token');
        if (!token) {
            history.push(ROUTE_ADMIN_LOGIN); // Redirect to login if no token is found
        } else {
            history.push(ROUTE_ADMIN_DASHBOARD);
        }
    }, [history]);

    return (
        <DashboardLayout />
    );
}

export default withRouter(Dashboard);