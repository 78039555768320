import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Popconfirm, Form, Tooltip, Checkbox, Row, Col } from 'antd';
import { EditOutlined, DeleteOutlined, SaveOutlined, ReloadOutlined } from '@ant-design/icons';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import axios from 'axios';
import Toast from 'layouts/Partials/Toast';

const Branch = ({ history }) => {
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/admin/login'); 
    }
  }, [history]);

  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [fileNames, setFileNames] = useState({});
  const [fileInputs, setFileInputs] = useState({});
  const [newItemKey, setNewItemKey] = useState(null); 
  const [filePreviews, setFilePreviews] = useState({}); 
  const [isNewItem, setIsNewItem] = useState(false); 

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/branch`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        const fetchedData = response.data.map(item => ({
          ...item,
          key: String(item.id),
          image_path_status: item.image_path_status === '1', 
          logo_path_status: item.logo_path_status === '1', 
        }));
        setDataSource(fetchedData);
        setFilteredData(fetchedData);
      } catch (error) {
        Toast('Failed to fetch data. Please try again later.', 2);  
      }
    };

    fetchData();
  }, []);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    if (isNewItem) {
      setDataSource(prev => prev.filter(item => item.key !== newItemKey));
      setFilteredData(prev => prev.filter(item => item.key !== newItemKey));
      setIsNewItem(false); 
    }
    setEditingKey('');
    form.resetFields(); 
  };
  
  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
  
      // Check if row data is defined
      if (row.title != undefined || row.content != undefined) {
        const formData = new FormData();
        formData.append('title', row.title);
        formData.append('content', row.content);
        formData.append('image_path_status', row.image_path_status ? 1 : 0);  // Save checkbox value
        formData.append('logo_path_status', row.logo_path_status ? 1 : 0);    // Save checkbox value
  
        if (fileInputs[key]?.image_path) {
          formData.append('image_path', fileInputs[key].image_path);
        }
        if (fileInputs[key]?.logo_path) {
          formData.append('logo_path', fileInputs[key].logo_path);
        }
  
        let response;
  
        if (isNewItem) {
          // POST request for adding new branch
          response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/branch`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
  
          // Create a new item with the response data
          const newItem = {
            ...row,
            id: response.data.id,
            key: String(response.data.id),
            image_path: response.data.img_path || '',
            logo_path: response.data.logo_path || '',
          };
  
          // Only set the data source once after successfully saving
          setDataSource((prev) => [newItem, ...prev.filter(item => item.key !== newItemKey)]);
          setFilteredData((prev) => [newItem, ...prev.filter(item => item.key !== newItemKey)]);
  
          // Reset `isNewItem` and `newItemKey`
          setIsNewItem(false);
          setNewItemKey(null);
          
        } else {
          // PUT request for updating existing branch
          response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/branch/${newData[index].id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
  
          const updatedItem = {
            ...newData[index],
            ...row,
            image_path: response.data.img_path || newData[index].image_path,
            logo_path: response.data.logo_path || newData[index].logo_path,
          };
  
          newData.splice(index, 1, updatedItem);
          setDataSource(newData);
          setFilteredData(newData);
        }
  
        // Reset states after saving
        setEditingKey('');
        setFileInputs((prev) => ({ ...prev, [key]: {} }));
        Toast('Branch saved successfully!', 1);
      } else {
        Toast('The title and content fields are empty.', 2);
      }
    } catch (err) {
      console.error('Validation Failed:', err);
      Toast('Failed to save item. Please try again.', 2);
    }
  };
  


  
  const deleteRow = async (key) => {
    const itemToDelete = dataSource.find((item) => item.key === key);

    if (itemToDelete) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_BASE_URL}api/branch/${itemToDelete.id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(newData);
        setFilteredData(newData);
        Toast('Item deleted successfully!', 1);
      } catch (error) {
        console.error('Failed to delete item:', error);
        Toast('Failed to delete item. Please try again.', 2);
      }
    }
  };

  const addItem = () => {
    if (editingKey) {
      cancel(); // Cancel any ongoing edits
    }
  
    const newItem = {
      key: String(Date.now()),
      image_path: '',
      title: '',
      content: '',
      logo_path: '',
      image_path_status: false, // Unchecked by default
      logo_path_status: true,   // Checked by default
    };
  
    setDataSource((prev) => [newItem, ...prev]);
    setFilteredData((prev) => [newItem, ...prev]);
    setEditingKey(newItem.key);
    setNewItemKey(newItem.key);
    setIsNewItem(true);
  
    // Set the initial form values, including the checkboxes
    form.setFieldsValue({
      title: newItem.title,
      content: newItem.content,
      image_path_status: newItem.image_path_status,
      logo_path_status: newItem.logo_path_status,
    });
  };
  
  
  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = dataSource.filter(item =>
      Object.values(item).some(val => String(val).toLowerCase().includes(value.toLowerCase()))
    );
    setFilteredData(filtered);
    setPagination({ ...pagination, current: 1 });
  };

  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      render: (_, __, index) => index + 1 + (pagination.current - 1) * pagination.pageSize
    },
    {
      title: 'Image',
      dataIndex: 'image_path',
      render: (text, record) => renderImageField(text, record, 'image_path', isEditing(record))
    },
    {
      title: 'Image Status',
      dataIndex: 'image_path_status',
      render: (text, record) => (
        isEditing(record) ? (
          <Form.Item name="image_path_status" valuePropName="checked" style={{ margin: 0 }}>
            <Checkbox />
          </Form.Item>
        ) : (
          <Checkbox checked={record.image_path_status} /> // Boolean value controls checked state
        )
      )
    },
    {
      title: 'Title',
      dataIndex: 'title',
      render: (text, record) => (
        isEditing(record) ? (
          <Form.Item name="title" style={{ margin: 0 }}>
            <Input placeholder="Enter title" />
          </Form.Item>
        ) : (
          <Tooltip title={text || 'No title available'}>
            <span>{text}</span>
          </Tooltip>
        )
      )
    },
    {
      title: 'Content',
      dataIndex: 'content',
      render: (text, record) => (
        isEditing(record) ? (
          <Form.Item name="content" style={{ margin: 0 }}>
            <Input placeholder="Enter content" />
          </Form.Item>
        ) : (
          <Tooltip title={text || 'No content available'}>
            <span>{text}</span>
          </Tooltip>
        )
      )
    },
    {
      title: 'Logo',
      dataIndex: 'logo_path',
      render: (text, record) => renderImageField(text, record, 'logo_path', isEditing(record))
    },
    {
      title: 'Logo Status',
      dataIndex: 'logo_path_status',
      render: (text, record) => (
        isEditing(record) ? (
          <Form.Item name="logo_path_status" valuePropName="checked" style={{ margin: 0 }}>
            <Checkbox />
          </Form.Item>
        ) : (
          <Checkbox checked={record.logo_path_status} /> // Boolean value controls checked state
        )
      )
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <span>
            <Button
              type="link"
              icon={<SaveOutlined />}
              onClick={() => save(record.key)}
              style={{ marginRight: 8 }}
            />
            <Popconfirm title="Sure to cancel?" onConfirm={cancel}>
              <Button type="link" icon={<ReloadOutlined />} />
            </Popconfirm>
          </span>
        ) : (
          <span>
            <Button onClick={() => edit(record)} type="link" className="btn-admin-edit"><EditOutlined /></Button>
            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRow(record.key)}>
              <Button type="link" className="btn-admin-delete"><DeleteOutlined /></Button>
            </Popconfirm>
          </span>
        );
      }
    }
  ];
  

  const renderImageField = (text, record, field, isEditing) => {
    const imageUrl = filePreviews[record.key]?.[field] || (record[field] ? `${process.env.REACT_APP_API_BASE_URL}uploads/media/${record[field]}` : ''); 
    
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {imageUrl && (
          <img
            src={imageUrl}
            alt={field}
            style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '8px' }}
          />
        )}
        {isEditing ? (
          <>
            <input
              type="file"
              id={`file-input-${record.key}-${field}`}
              style={{ display: 'none' }}
              onChange={(e) => handleFileChange(e, field, record)}
            />
            <Button
              onClick={() => handleButtonClick(field, record)}
              style={{ 
                backgroundColor: 'green', // Always set the background to green when editing
                color: 'white',
              }}
            >
              {fileNames[record.key]?.[field] || `Upload ${field.replace('_', ' ')}`}
            </Button>
          </>
        ) : (
          <Tooltip title={text || 'No file path available'}>
            <span>{text && text.length > 10 ? `${text.slice(0, 5)}...${text.slice(-5)}` : text}</span>
          </Tooltip>
        )}
      </div>
    );
  };

  const handleFileChange = (e, field, record) => {
    const file = e.target.files[0];
    if (file) {
      const newFileName = file.name;
      const filePreviewUrl = URL.createObjectURL(file);

      setFileNames((prev) => ({
        ...prev,
        [record.key]: { ...prev[record.key], [field]: newFileName }
      }));

      setFilePreviews((prev) => ({
        ...prev,
        [record.key]: { ...prev[record.key], [field]: filePreviewUrl }
      }));

      setFileInputs((prev) => ({
        ...prev,
        [record.key]: { ...prev[record.key], [field]: file }
      }));

      form.setFieldsValue({ [field]: newFileName });
    }
  };

  const handleButtonClick = (field, record) => {
    document.getElementById(`file-input-${record.key}-${field}`).click();
  };

  const handleFileInputChange = (e, key, fieldName) => {
    const file = e.target.files[0];
    if (file) {
      setFileInputs((prevInputs) => ({
        ...prevInputs,
        [key]: {
          ...prevInputs[key],
          [fieldName]: file,
        },
      }));

      const reader = new FileReader();
      reader.onload = (event) => {
        setFilePreviews((prevPreviews) => ({
          ...prevPreviews,
          [key]: {
            ...prevPreviews[key],
            [fieldName]: event.target.result,
          },
        }));
      };
      reader.readAsDataURL(file);
    }
  };

  return (
    <AdminLayout>
      <Form form={form} component={false}>
        <div style={{ overflowX: 'auto' }}>
          <Table
            dataSource={filteredData}
            columns={columns}
            pagination={pagination}
            onChange={(pagination) => setPagination(pagination)}
            rowClassName="editable-row"
            scroll={{ x: true }}  // Enable horizontal scroll for the table
          />
        </div>
      </Form>
    </AdminLayout>
  );
};

export default Branch;