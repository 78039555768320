import React, { useEffect, useState } from 'react';
import { Table, Button, Input, Popconfirm, Form, Tooltip, Row, Col, Checkbox } from 'antd';
import { EditOutlined, DeleteOutlined, SaveOutlined, ReloadOutlined } from '@ant-design/icons';
import AdminLayout from 'layouts/AdminLayout/AdminLayout';
import axios from 'axios';
import Toast from 'layouts/Partials/Toast';

const Event = ({ history }) => {
  useEffect(() => {
    const token = localStorage.getItem('token');
    if (!token) {
      history.push('/admin/login'); 
    }
  }, [history]);

  const [dataSource, setDataSource] = useState([]);
  const [editingKey, setEditingKey] = useState('');
  const [form] = Form.useForm();
  const [pagination, setPagination] = useState({ current: 1, pageSize: 10 });
  const [searchText, setSearchText] = useState('');
  const [filteredData, setFilteredData] = useState([]);
  const [fileNames, setFileNames] = useState({});
  const [fileInputs, setFileInputs] = useState({});
  const [newItemKey, setNewItemKey] = useState(null); 
  const [filePreviews, setFilePreviews] = useState({}); 
  const [isNewItem, setIsNewItem] = useState(false); 
  const [newActiveItem, setNewActiveItem] = useState(1);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const response = await axios.get(`${process.env.REACT_APP_API_BASE_URL}api/event`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });
        console.log("bbb", response);
        const fetchedData = response.data.map(item => ({ ...item, key: String(item.id) }));
        setDataSource(fetchedData);
        setFilteredData(fetchedData);
      } catch (error) {
        Toast('Failed to fetch data. Please try again later.', 2);  
      }
    };

    fetchData();
  }, []);

  const isEditing = (record) => record.key === editingKey;

  const edit = (record) => {
    form.setFieldsValue({ ...record });
    setEditingKey(record.key);
  };

  const cancel = () => {
    if (isNewItem) {
      setDataSource(prev => prev.filter(item => item.key !== newItemKey));
      setFilteredData(prev => prev.filter(item => item.key !== newItemKey));
      setIsNewItem(false); 
    }
    setEditingKey('');
    form.resetFields(); 
  };

  const save = async (key) => {
    try {
      const row = await form.validateFields();
      const newData = [...dataSource];
      const index = newData.findIndex((item) => key === item.key);
  
      if (row.title != undefined || row.content != undefined) {
        const formData = new FormData();
        formData.append('title', row.title);
        formData.append('content', row.content);
        formData.append('link', row.link);
        formData.append('active', newActiveItem); // Use the latest value from the form
        formData.append('image_path_status', row.image_path_status ? 1 : 0);
        formData.append('logo_path_status', row.logo_path_status ? 1 : 0);
  
        // Add file inputs if they exist
        if (fileInputs[key]?.image_path) {
          formData.append('image_path', fileInputs[key].image_path);
        }
        if (fileInputs[key]?.logo_path) {
          formData.append('logo_path', fileInputs[key].logo_path);
        }
  
        let response;
  
        if (isNewItem) {
          response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/event`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
  
          const newItem = {
            ...row,
            id: response.data.id,
            key: String(response.data.id),
            active: newActiveItem,
            image_path: response.data.img_path || '',
            logo_path: response.data.logo_path || '',
          };
  
          setDataSource((prev) => [newItem, ...prev.filter(item => item.key !== newItemKey)]);
          setFilteredData((prev) => [newItem, ...prev.filter(item => item.key !== newItemKey)]);
          setIsNewItem(false);
          setNewItemKey(null);
        } else {
          response = await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/event/${newData[index].id}`, formData, {
            headers: {
              'Content-Type': 'multipart/form-data',
              'Authorization': `Bearer ${localStorage.getItem('token')}`
            }
          });
  
          const updatedItem = {
            ...newData[index],
            ...row,
            image_path: response.data.img_path || newData[index].image_path,
            logo_path: response.data.logo_path || newData[index].logo_path,
          };
  
          newData.splice(index, 1, updatedItem);
          setDataSource(newData);
          setFilteredData(newData);
        }
  
        setEditingKey('');
        setFileInputs((prev) => ({ ...prev, [key]: {} }));
        Toast('Branch saved successfully!', 1);
      } else {
        Toast('The title and content fields are empty.', 2);
      }
    } catch (err) {
      console.error('Validation Failed:', err);
      Toast('Failed to save item. Please try again.', 2);
    }
  };
  
  
  
  const deleteRow = async (key) => {
    const itemToDelete = dataSource.find((item) => item.key === key);

    if (itemToDelete) {
      try {
        await axios.delete(`${process.env.REACT_APP_API_BASE_URL}api/event/${itemToDelete.id}`, {
          headers: {
            'Authorization': `Bearer ${localStorage.getItem('token')}`
          }
        });

        const newData = dataSource.filter((item) => item.key !== key);
        setDataSource(newData);
        setFilteredData(newData);
        Toast('Item deleted successfully!', 1);
      } catch (error) {
        console.error('Failed to delete item:', error);
        Toast('Failed to delete item. Please try again.', 2);
      }
    }
  };

  const addItem = () => {
    if (editingKey) {
      cancel();
    }
  
    const newItem = {
      key: String(Date.now()),
      image_path: '',
      title: '',
      content: '',
      link: '',
      logo_path: '',
      active: true,
      image_path_status: false,
      logo_path_status: true,
    };
  
    setDataSource((prev) => [newItem, ...prev]);
    setFilteredData((prev) => [newItem, ...prev]);
    setEditingKey(newItem.key);
    setNewItemKey(newItem.key);
    setIsNewItem(true);
  
    form.setFieldsValue(newItem); // Set all fields including checkbox states
  };
  
  const handleSearch = (value) => {
    setSearchText(value);
    const filtered = dataSource.filter(item =>
      Object.values(item).some(val => String(val).toLowerCase().includes(value.toLowerCase()))
    );
    setFilteredData(filtered);
    setPagination({ ...pagination, current: 1 });
  };

  const handleCheckboxChange = async (e, record) => {
    const newActiveStatus = e.target.checked ? 1 : 0;
    setNewActiveItem(newActiveStatus);
    form.setFieldsValue({ active: newActiveStatus });
  
    try {
      await axios.post(`${process.env.REACT_APP_API_BASE_URL}api/event/active/${record.id}`, {
        active: newActiveStatus
      }, {
        headers: {
          'Content-Type': 'multipart/form-data',
          'Authorization': `Bearer ${localStorage.getItem('token')}`
        }
      });
  
      const newData = dataSource.map(item =>
        item.key === record.key ? { ...item, active: newActiveStatus } : item
      );
  
      setDataSource(newData);
      setFilteredData(newData);
      Toast('Status updated successfully!', 1);
    } catch (error) {
      console.error('Failed to update status:', error);
      Toast('Failed to update status. Please try again.', 2);
    }
  };
  
  const columns = [
    {
      title: 'No',
      dataIndex: 'no',
      render: (_, __, index) => index + 1 + (pagination.current - 1) * pagination.pageSize
    },
    {
      title: 'Image',
      dataIndex: 'image_path',
      render: (text, record) => renderImageField(text, record, 'image_path', isEditing(record))
    },
    {
      title: 'Title',
      dataIndex: 'title',
      render: (text, record) => (
        isEditing(record) ? (
          <Form.Item name="title" style={{ margin: 0 }}>
            <Input placeholder="Enter title" />
          </Form.Item>
        ) : (
          <Tooltip title={text || 'No title available'}>
            <span>{text}</span>
          </Tooltip>
        )
      )
    },
    {
      title: 'Link',
      dataIndex: 'link',
      render: (text, record) => (
        isEditing(record) ? (
          <Form.Item name="link" style={{ margin: 0 }}>
            <Input placeholder="Enter link" />
          </Form.Item>
        ) : (
          <Tooltip title={text || 'No link available'}>
            <span>{text}</span>
          </Tooltip>
        )
      )
    },
    {
      title: 'Active',
      dataIndex: 'active',
      render: (text, record) => {
        const editable = isEditing(record);
    
        return (
          <Checkbox
            checked={record.active == 1}
            onChange={(e) => handleCheckboxChange(e, record)}
  
          />
        );
      }
    },
    {
      title: 'Action',
      dataIndex: 'action',
      render: (_, record) => {
        const editable = isEditing(record);
        return editable ? (
          <>
            <Button onClick={() => save(record.key)} type="link" className="btn-admin-edit"><SaveOutlined /></Button>
            <Button onClick={cancel} type="link" className="btn-admin-delete"><ReloadOutlined /></Button>
          </>
        ) : (
          <>
            <Button onClick={() => edit(record)} type="link" className="btn-admin-edit"><EditOutlined /></Button>
            <Popconfirm title="Sure to delete?" onConfirm={() => deleteRow(record.key)}>
              <Button type="link" className="btn-admin-delete"><DeleteOutlined /></Button>
            </Popconfirm>
          </>
        );
      },
    },
  ];

  const renderImageField = (text, record, field, isEditing) => {
    const imageUrl = filePreviews[record.key]?.[field] || (record[field] ? `${process.env.REACT_APP_API_BASE_URL}uploads/media/${record[field]}` : ''); 
    
    return (
      <div style={{ display: 'flex', alignItems: 'center' }}>
        {imageUrl && (
          <img
            src={imageUrl}
            alt={field}
            style={{ width: '50px', height: '50px', objectFit: 'cover', marginRight: '8px' }}
          />
        )}
        {isEditing ? (
          <>
            <input
              type="file"
              id={`file-input-${record.key}-${field}`}
              style={{ display: 'none' }}
              onChange={(e) => handleFileChange(e, field, record)}
            />
            <Button
              onClick={() => handleButtonClick(field, record)}
              style={{ 
                backgroundColor: 'green', // Always set the background to green when editing
                color: 'white',
              }}
            >
              {fileNames[record.key]?.[field] || `Upload ${field.replace('_', ' ')}`}
            </Button>
          </>
        ) : (
          <Tooltip title={text || 'No file path available'}>
            <span>{text && text.length > 10 ? `${text.slice(0, 5)}...${text.slice(-5)}` : text}</span>
          </Tooltip>
        )}
      </div>
    );
  };
  
  const handleFileChange = (e, field, record) => {
    const file = e.target.files[0];
    if (file) {
      const newFileName = file.name;
      const filePreviewUrl = URL.createObjectURL(file);

      setFileNames((prev) => ({
        ...prev,
        [record.key]: { ...prev[record.key], [field]: newFileName }
      }));

      setFilePreviews((prev) => ({
        ...prev,
        [record.key]: { ...prev[record.key], [field]: filePreviewUrl }
      }));

      setFileInputs((prev) => ({
        ...prev,
        [record.key]: { ...prev[record.key], [field]: file }
      }));

      form.setFieldsValue({ [field]: newFileName });
    }
  };

  const handleButtonClick = (field, record) => {
    document.getElementById(`file-input-${record.key}-${field}`).click();
  };

  return (
    <AdminLayout>
      <div className="container-table">
        <Form form={form} component={false}>
          <Table
            dataSource={filteredData}
            columns={columns}
            rowClassName="editable-row"
            pagination={pagination}
            onChange={setPagination}
            scroll={{ x: true }} 
          />
        </Form>
      </div>
    </AdminLayout>
  );
};

export default Event;